import React, { FC } from "react";
import { graphql } from "gatsby";
import { TypeImage, TypeProject } from "../types";
import Layout from "../components/Layout/Layout";
import GalleryGrid from "../components/ui/Lightbox/GalleryGrid";
import Button from "../components/ui/button/Button";
import HeadMeta from "../components/HeadMeta";

interface ISingleProject {
    data: {
        allFile: {
            images: TypeImage[]
        }
    },
    pageContext: TypeProject & {
        prevProject: TypeProject
        nextProject: TypeProject
        locale: string
    }
}

const SingleProject: FC<ISingleProject> = ( { data, pageContext } ) => {
    const images = data.allFile.images;
    const { locale: l } = pageContext

    return (
        <Layout>
            <HeadMeta title={ pageContext.name } description={ pageContext.description[l] } image={ data.allFile.images[0].childImageSharp.original.src }/>
            <div className={ "mb--lg" }>
                <h1>{ pageContext.name }</h1>
                <p className={"max-w--60ch mt--sm"}>{ pageContext.description[l] }</p>
            </div>
            <GalleryGrid images={ images }/>
            <nav className={ "flex--center my--lg jf__cnt--between" }>
                <Button look={ "minimal" } to={ `/projects/${ pageContext.prevProject.slug }` }>
                    <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 36 36">
                        <path d="M23.12 11.12L21 9l-9 9 9 9 2.12-2.12L16.24 18z"/>
                    </svg>
                    { pageContext.prevProject.name }
                </Button>
                <Button look={ "minimal" } to={ `/projects/${ pageContext.nextProject.slug }` }>
                    { pageContext.nextProject.name }
                    <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 36 36">
                        <path d="M15 9l-2.12 2.12L19.76 18l-6.88 6.88L15 27l9-9z"/>
                    </svg>
                </Button>
            </nav>
        </Layout>
    )
}

export default SingleProject

export const query = graphql`
    query ($imgPath: String! ) {
        allFile(
            sort: {fields: name}
            filter: {extension: {regex: "/jpeg|jpg|png|gif/"}, relativeDirectory: {eq: $imgPath}}
        ) {
            images: nodes {
                relativePath
                childImageSharp {
                    original {
                        src
                    }
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
`
