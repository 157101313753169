import * as React from "react";
import { FunctionComponent, memo, useEffect, useState } from "react";
import Image from "../Image";
import Gallery from "react-photo-gallery";
import { motion } from "framer-motion";
import Lightbox from "./Lightbox";
import { useCheckForScreens } from "../../../utilities";

interface LightBoxGalleryProps {
    images: any
    desktopColumns?: number
    masonry?: true
}


const LightBoxGallery: FunctionComponent<LightBoxGalleryProps> = memo( ( { images, desktopColumns = 3, masonry } ) => {
    const [ columns, setColumns ] = useState( desktopColumns )
    const { screen } = useCheckForScreens()
    useEffect( () => {
        if (screen === "phone") {
            setColumns( 1 )
        } else if (screen === "tablet") {
            setColumns( 2 )
        } else if (screen === "laptop") {
            setColumns( desktopColumns )
        } else if (screen === "desktop") {
            setColumns( desktopColumns )
        }
    }, [ screen ] );

    const GalleryImages = images.map( img => {
        return {
            width : img.childImageSharp.gatsbyImageData.width,
            height: img.childImageSharp.gatsbyImageData.height,
            src   : img.childImageSharp.original.src,
            ...img
        }
    } )

    if (masonry) {
        return (
            <Lightbox items={ GalleryImages }
                      allChildren={ ( { handleOpen }, ) => {
                          return (
                              <Gallery photos={ GalleryImages }
                                       direction={ images.length > 0 ? "column" : "row" }
                                       columns={ columns }
                                       margin={ 5 }
                                       renderImage={ ( { margin, photo, left, top, index } ) => {
                                           return (
                                               <div className={ "cursor--pointer" } key={ index }
                                                    role={ "button" }
                                                    tabIndex={ 0 }
                                                    onKeyPress={ () => handleOpen( index ) }
                                                    style={ {
                                                        width   : photo.width,
                                                        height  : photo.height,
                                                        margin,
                                                        position: "absolute",
                                                        left,
                                                        top
                                                    } }
                                                    onClick={ () => handleOpen( index ) }
                                               >
                                                   <motion.div className={ "w--100 h--100" }
                                                               variants={ imgVariants }
                                                               initial={ "init" }
                                                               animate={ "in" }

                                                               transition={ {
                                                                   duration: .5,
                                                                   delay   : index * .1
                                                               } }>
                                                       <Image data={ images[index].childImageSharp.gatsbyImageData } alt={ "" } className={ "h--100" }/>
                                                       <div className="overlay"/>
                                                   </motion.div>
                                               </div>

                                           )
                                       } }
                              />
                          )
                      } }
            />
        )
    }
    return (
        <Lightbox items={ GalleryImages }
                  allChildren={ ( { handleOpen }, ) => {
                      return (
                          <motion.div className={ "gallery" }
                                      variants={ containerVariants }
                                      initial={ "init" }
                                      animate={ "in" }
                          >
                              { GalleryImages.map( ( image, index ) => (
                                  <motion.div key={ image.src }
                                              className={ "gallery__item relative w--100 h--thumb cursor--pointer overflow--hidden" }
                                              onKeyPress={ () => handleOpen( index ) }
                                              onClick={ () => handleOpen( index ) }
                                              role={ "button" }
                                              tabIndex={ 0 }
                                              variants={ imgVariants }
                                              transition={ {
                                                  type  : "spring",
                                                  bounce: 0.25
                                              } }>
                                      <Image data={ images[index].childImageSharp.gatsbyImageData } alt={ "" } className={ "h--100" }/>
                                      <div className="gallery__item__overlay flex--center-middle absolute inset--0 bg--overlay">
                                          <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 0 24 24" className={ "fill--gray-1 gallery__item__overlay__icon" }>
                                              <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
                                          </svg>
                                      </div>
                                  </motion.div>
                              ) ) }
                          </motion.div>
                      )
                  } }
        />
    )
} )

export default LightBoxGallery

const containerVariants = {
    init: {},
    in  : {
        transition: {
            staggerChildren: .15
        }
    }
}

const imgVariants = {
    init: {
        scale  : 0,
        opacity: 0,
    },
    in  : {
        scale  : 1,
        opacity: 1,

    }
}
